import React from "react"
import ShopPage from "../../components/ShopPage/ShopPage"
import Utils from "../../utils/node.utils"
import { StaticQuery, graphql } from "gatsby"
import { getSubCategories } from "../../contentfunctions/shop.js"

const JewelryPage = ({ location }) => {
  Utils.NavigateToBrowserLanguage("nl", "/jewelry", location.state)
  return (
    <StaticQuery
      query={pageQuery}
      render={data => {
        const contentMap = Utils.ContentListToMap(data.shopContent.item.content)
        const commonContentMap = Utils.ContentListToMap(
          data.commonContent.item.content
        )
        const categories = getSubCategories(data.categories.edges, false, {
          locale: "nl",
          filter: "Jewelry",
        })

        return (
          <ShopPage
            title={contentMap.seo_title_jewelry.value}
            description={contentMap.seo_description_jewelry.value}
            breadCrumbs={["menu_home", "menu_jewelry"]}
            mainCategories={categories.mainCategories}
            subCategories={categories.subCategories}
            breadCrumb={"jewelry"}
            location={location}
            originPage="jewelry"
            language="nl"
            contentMap={contentMap}
            commonContentMap={commonContentMap}
            products={data.products.edges}
            keywords={Utils.GetKeywordsFromTags(data.shopContent.item.tags)}
          />
        )
      }}
    />
  )
}

export default JewelryPage

const pageQuery = graphql`
  {
    commonContent: commonContentNlnl {
      item {
        key
        content {
          key
          value
        }
      }
    }

    shopContent: shopContentNlnl {
      item {
        key
        tags {
          content {
            key
            value
          }
        }
        content {
          key
          value
        }
      }
    }

    categories: allCategoriesNlnl(sort: { fields: [item___key], order: ASC }) {
      edges {
        node {
          item {
            id
            key
            content {
              key
              value
            }
            page_children {
              id
              key
              content {
                key
                value
              }
            }
          }
        }
      }
    }

    products: allProductsNlnl(
      sort: { fields: [item___collectionIndex], order: ASC }
    ) {
      edges {
        node {
          item {
            id
            key
            slug
            priority
            private
            data {
              quantity
              price {
                value
                discount_value
                currency
              }
            }
            categories {
              key
              content {
                key
                value
              }
            }
            content {
              key
              value
            }
            variation_sequences {
              data {
                quantity
                price {
                  value
                  discount_value
                  currency
                }
              }
            }
          }
        }
      }
    }
  }
`
